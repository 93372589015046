import React from 'react';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

const featurebox = () => (
  <div>
    <div className='row justify-content-center'>
      <div className="col-lg-4 col-md-6 mb-3 ">
        <div className="feature-box f-boxed style-3 h-100">
          <Reveal className='onStep' keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
            <i className="bg-color-2 i-boxed   icon_lightbulb"></i>
          </Reveal>
          <div className="text">
            <Reveal className='onStep' keyframes={fadeInUp} delay={100} duration={600} triggerOnce>
              <h4 className="">Solution Architect </h4>
            </Reveal>
            <Reveal className='onStep' keyframes={fadeInUp} delay={200} duration={600} triggerOnce>
              <p className="">With the vast experience in the Industry, together we create a unique and innovative solution which most suitable to your business with Design thinking.</p>
            </Reveal>
          </div>
          <i className="wm  icon_lightbulb"></i>
        </div>
      </div>

      <div className="col-lg-4 col-md-6 mb-3  ">
        <div className="feature-box f-boxed style-3 h-100">
          <Reveal className='onStep' keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
            <i className=" bg-color-2 i-boxed   icon_pencil-edit"></i>
          </Reveal>
          <div className="text">
            <Reveal className='onStep' keyframes={fadeInUp} delay={100} duration={600} triggerOnce>
              <h4 className="">Rapid Prototyping</h4>
            </Reveal>
            <Reveal className='onStep' keyframes={fadeInUp} delay={200} duration={600} triggerOnce>
              <p className="">Visualising the product what you gain after the development  is truly important So
                within least 2 weeks, we prototype the best solution for your business.</p>
            </Reveal>
          </div>
          <i className="wm icon_pencil-edit"></i>
        </div>
      </div>

      <div className="col-lg-4 col-md-6 mb-3  ">
        <div className="feature-box f-boxed style-3  h-100">
          <Reveal className='onStep' keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
            <i className=" bg-color-2 i-boxed icon_building_alt"></i>
          </Reveal>
          <div className="text">
            <Reveal className='onStep' keyframes={fadeInUp} delay={100} duration={600} triggerOnce>
              <h4 className="">Build and Implementation</h4>
            </Reveal>
            <Reveal className='onStep' keyframes={fadeInUp} delay={200} duration={600} triggerOnce>
              <p className="">Our dedicated experts build up your solution using the best technologies with higher quality and  also saving time.</p>
            </Reveal>
          </div>
          <i className="wm icon_building_alt"></i>
        </div>
      </div>
    </div>


    <div className='row justify-content-center'>
      <div className="col-lg-4 col-md-6 mb-3 ">
        <div className="feature-box f-boxed style-3 h-100">
          <Reveal className='onStep' keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
            <i className="bg-color-2 i-boxed icon_tools"></i>
          </Reveal>
          <div className="text">
            <Reveal className='onStep' keyframes={fadeInUp} delay={100} duration={600} triggerOnce>
              <h4 className="">Maintenance</h4>
            </Reveal>
            <Reveal className='onStep' keyframes={fadeInUp} delay={200} duration={600} triggerOnce>
              <p className="">After the deployment, Operating and maintenance the software is the most crucial part, so we will handle everything for best results.
              </p>
            </Reveal>
          </div>
          <i className="wm icon_tools"></i>
        </div>
      </div>

      <div className="col-lg-4 col-md-6 mb-3">
        <div className="feature-box f-boxed style-3 h-100">
          <Reveal className='onStep' keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
            <i className=" bg-color-2 i-boxed   icon_search"></i>
          </Reveal>
          <div className="text">
            <Reveal className='onStep' keyframes={fadeInUp} delay={100} duration={600} triggerOnce>
              <h4 className="">Research and Development</h4>
            </Reveal>
            <Reveal className='onStep' keyframes={fadeInUp} delay={200} duration={600} triggerOnce>
              <p className="">Innovation & perpetual Improvement is the key to success in competitive business world so our expertise will bring up very creative ways improve your solution to the get best results and most importantly customer satisfaction.</p>
            </Reveal>
          </div>
          <i className="wm   icon_search"></i>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 mb-3">
        <div className="feature-box f-boxed style-3 h-100">
          <Reveal className='onStep' keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
            <i className="fa fa-lock"></i>
          </Reveal>
          <div className="text">
            <Reveal className='onStep' keyframes={fadeInUp} delay={100} duration={600} triggerOnce>
              <h4 className="">Security Audit</h4>
            </Reveal>
            <Reveal className='onStep' keyframes={fadeInUp} delay={200} duration={600} triggerOnce>
              <p className="">Security is one of the biggest concerns in web3. Our security audits help to figure out security loopholes and potential risks in any web3 application such as NFTs, Smart Contracts, Defis, dApps. With our comprehensive audit report we suggest best ways to eliminate loopholes and risks. It also helps to confirm reliability of your project for investors and potential partners.</p>
            </Reveal>
          </div>
          <i className="wm   fa fa-lock"></i>
        </div>
      </div>


    </div>
  </div>
);
export default featurebox;