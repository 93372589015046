import React from "react";

import imgSolidity from '../../assets/technologies/solidity.svg'
import imgWeb3 from '../../assets/technologies/web3js.png'
import imgEthereum from '../../assets/technologies/ethereum.png'
import imgRemix from '../../assets/technologies/remix.png'
import imgSolana from '../../assets/technologies/solana.png'
import imgTypescript from '../../assets/technologies/ts.png'
import imgRust from '../../assets/technologies/rust.png'
import imgBinance from '../../assets/technologies/binance.png'


const Technologies = () => (
    <section className='container no-bottom'>
        <div className="row justify-content-center">
            <div className="col-lg-3 col-sm-5 col-6 mb30">
                <span className="box-url  text-center">
                    <img width="70px" src={imgSolidity} alt="" className="mb20" />

                </span>
            </div>

            <div className="col-lg-3 col-sm-5 col-6 mb30">
                <span className="box-url   text-center ">
                    <div><img width="70px" src={imgWeb3} alt="" className="mb20" /></div>


                </span>
            </div>

            <div className="col-lg-3 col-sm-5 col-6 mb30">
                <span className="box-url  text-center">
                    <img width="70px" src={imgEthereum} alt="" className="mb20" />

                </span>
            </div>

            <div className="col-lg-3 col-sm-5 col-6 mb30">
                <span className="box-url  text-center">
                    <img width="70px" src={imgRemix} alt="" className="mb20" />

                </span>
            </div>

            <div className="col-lg-3 col-sm-5 col-6 mb30">
                <span className="box-url  text-center">
                    <img width="70px" src={imgSolana} alt="" className="mb20" />

                </span>
            </div>

            <div className="col-lg-3 col-sm-5 col-6 mb30">
                <span className="box-url  text-center">
                    <img width="70px" src={imgTypescript} alt="" className="mb20" />

                </span>
            </div>
            <div className="col-lg-3 col-sm-5 col-6 mb30">
                <span className="box-url  text-center">
                    <img width="70px" src={imgRust} alt="" className="mb20" />

                </span>
            </div>
            <div className="col-lg-3 col-sm-5 col-6 mb30">
                <span className="box-url  text-center">
                    <img width="70px" src={imgBinance} alt="" className="mb20" />

                </span>
            </div>
        </div>
    </section>
)

export default Technologies