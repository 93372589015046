import React from 'react';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

const slidermainzero = () => (
  <div className="container">
    <div className="row align-items-center">
      <div className="col-md-8 m-auto">
        <div className="spacer-single"></div>
        {/* <Reveal className='onStep' keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
          <h6 className="text-center"><span className="text-uppercase color">Gigaland Market</span></h6>
        </Reveal> */}
        <div className="spacer-half"></div>
        {/* <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={600}>
          <h2 className="text-center">About Us</h2>
        </Reveal>
        <Reveal className='onStep' keyframes={fadeInUp} delay={600} duration={600}>
          <p className=" lead  text-center">
            We are a team of software engineers specialized in blockchain technology with wide experience on cryptography and cyber security.
          </p>
        </Reveal> */}
        <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={600}>
          <h2 className="text-center">Why AXCER</h2>
        </Reveal>
        <Reveal className='onStep' keyframes={fadeInUp} delay={600} duration={600}>
          <p className=" lead  text-center">
            We are a team of software engineers experienced in blockchain technology and also other popular and widely used technologies.
            We collaborate with agile methodology. So that is most important because we can build up your idea into a great product by saving time.
          </p>
        </Reveal>
        {/* <Reveal className='onStep' keyframes={fadeInUp} delay={800} duration={600}>
          <div className='d-flex justify-content-center'>
            <span onClick={() => window.open("#", "_self")} className="btn-main inline lead">Explore</span>
          </div>
          <div className="spacer-single"></div>
          <div className="spacer-half"></div>
        </Reveal> */}
        {/* <Reveal className='onStep' keyframes={fadeInUp} delay={1000} duration={600}>
              <div className="wallet-images-group-1">
                <img src="./img/wallet/1.png" alt=""/>
                <img src="./img/wallet/2.png" alt=""/>
                <img src="./img/wallet/3.png" alt=""/>
                <img src="./img/wallet/4.png" alt=""/>
                <img src="./img/wallet/5.png" alt=""/>
                <img src="./img/wallet/6.png" alt=""/>
                <img src="./img/wallet/7.png" alt=""/>
                <img src="./img/wallet/8.png" alt=""/>
              </div>
              </Reveal> */}
      </div>
    </div>
  </div>
);
export default slidermainzero;