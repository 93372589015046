import ReactFlow from 'react-flow-renderer';

import defaultNodes from './nodes.js';
import defaultEdges from './edges.js';

const edgeOptions = {
    animated: true,

    style: {
        stroke: 'white',
    },
};

const connectionLineStyle = { stroke: 'white' };

export default function Flow() {
    return (

        <ReactFlow
            defaultNodes={defaultNodes}
            minZoom={0.9}
            maxZoom={1}
            defaultEdges={defaultEdges}
            defaultEdgeOptions={edgeOptions}
            fitView
            style={{
                backgroundColor: '#FFFFFF',
            }}
            connectionLineStyle={connectionLineStyle}
        />

    );
}
