import React from 'react';
import SliderMainParticleGrey from '../components/Slider';
import CarouselCollectionRedux from '../components/CarouselCollectionReduxNew';
import CarouselNewRedux from '../components/CarouselNewReduxNew';
import AuthorListRedux from '../components/AuthorListRedux';
import Catgor from '../components/Catgor';
import Catgor1 from '../components/Catgor1';
import Catgor2 from '../components/Catgor2';

import Content1 from "../components/content1"
import Content2 from "../components/content2"
import Content3 from "../components/content3"

import FAQ from '../components/faq';

// import Footer from '../components/footer';
import Footer from '../components/Footer/FooterFive'
import Form from './form'

import Flow from '../flow';

import SliderMainZero from '../components/SliderMainZero1';


import Wallet from '../components/wallet';
import FeatureBox from '../components/FeatureBox';

import Technologies from './Technologies1';

import Particle from '../components/Particle';

import img from '../../assets/3.gif'

import { createGlobalStyle } from 'styled-components';
const GlobalStyles = createGlobalStyle`
  .navbar{
    background: #fff;
  }
  .navbar.sticky{
    position:fixed !important;
  }
  .col-white {
      color: #0d0c22;
  }
  .de_count{
    h3{
      font-size: 36px;
      margin-bottom: 0;
      span {
        font-size: 36px;
      }
    }
  }
  .btn-main.inline.white{
    background: rgba(131,100,266, .2);
  }
  .yelbg{
    background-color: rgb(255, 249, 225);
  }
  .yelbg-2{
    background-color: rgb(247, 255, 231);
  }
  .greybg{
    background-color: rgb(246, 245, 251);
  }
  h2{
    font-size: 30px;
    margin-bottom: 20px;
    margin-top:0;
  }
  .pb60 {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  .title {
    text-align:center
  }
  @media only screen and (max-width: 1199px) { 
    .breadcumb.h-vh{
      background-image: none !important;
    }
  }
@media (max-width: 544px) {  
  h1 {font-size:2.3rem;} 
  /* .jumbotron {
    margin-top:50px;
  } */
}
@media (max-width: 544px) {  
  h2 {font-size:1.3rem;} 
}
.slider-align {
  display: flex;
  align-items: center;
  padding-left: 30px;
}
@media (max-width: 544px) {
  .slider-align {  
  align-items: left;
  padding-left: 3px;
  }
  .mobile-margin {
    margin-top:-100px;
  }
  .title{
    text-align:left
  }
}
.mobile-margin {
    margin-top:auto;
  }

@media (max-width: 1200px) {
  .mobile-margin {
    margin-top:-100px;
  }
}
/* .gradient-background {
  background: radial-gradient(circle, rgba(131,100,226,0.6730042358740371) 0%, rgba(255,255,255,0) 12%, rgba(255,255,255,0) 35%, rgba(255,255,255,0) 79%, rgba(255,255,255,0) 100%);
}
@media (max-width: 769px) {
  .gradient-background {
  background: radial-gradient(circle, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 12%, rgba(255,255,255,0) 35%, rgba(255,255,255,0) 79%, rgba(255,255,255,0) 100%);
}
} */
`;


const home = () => (
  <div
  // style={{ background: `linear-gradient(90deg, rgba(131,100,226,0.36207986612613796) 0%, rgba(255,255,255,0) 14%, rgba(255,255,255,0) 48%, rgba(255,255,255,0) 86%, rgba(131,100,226,0.36207986612613796) 100%)` }}
  >
    <GlobalStyles />
    <section id='home' className="jumbotron no-bg h-vh "
    // style={{ background: `linear-gradient(90deg, rgba(131,100,226,0.6477941518404237) 0%, rgba(255,255,255,0) 26%, rgba(255,255,255,0) 39%, rgba(255,255,255,0) 47%, rgba(255,255,255,0) 87%)` }}
    >

      <div className='row mx-1 p-1  d-flex justify-content-between  flex-sm-row flex-column container-fluid  mobile-margin'>
        {/* <div className='row mx-1 px-1  d-flex justify-content-center  flex-sm-row flex-column container-fluid  mobile-margin'> */}
        <div className=' col-md-6 slider-align '>
          <Particle />

          <SliderMainParticleGrey />
        </div>

        <div className=' col-md-5  '>
          {/* <Form /> */}
          <Flow />

        </div>
      </div>

    </section >

    {/* <section id='about' className="jumbotron no-bg h-vh" >
      <SliderMainZero />
    </section> */}

    {/* <section id='about' className="jumbotron no-bg h-vh" >
      <Content1 />
    </section>

    <section className="jumbotron no-bg h-vh" >
      <Content2 />
    </section>

    <section className="jumbotron no-bg h-vh" >
      <Content3 />
    </section> */}
    {/* <section className='container'>
      <Catgor />
    </section> */}

    <section id='services' className='pb60 pt-5'>
      <div className='container pt-5'>
        <div className='row'>
          <div className='col-lg-12 title'>
            <h2> Newest Trends </h2>
          </div>
          <div className='col-lg-12 mt-5'>
            <Catgor />
          </div>
        </div>
      </div>
    </section>



    <section id='services' className='pb60 pt-5'>
      <div className='container pt-5'>
        <div className='row'>
          <div className='col-lg-12 title'>
            <h2> Our Services </h2>
          </div>
          <div className='col-lg-12'>
            <FeatureBox />
          </div>
        </div>
      </div>
    </section>


    <section id="book" className='pb60 pt-5'
    // style={{ background: `linear-gradient(90deg, rgba(131,100,226,0.6477941518404237) 0%, rgba(255,255,255,0) 26%, rgba(255,255,255,0) 39%, rgba(255,255,255,0) 47%, rgba(255,255,255,0) 87%)` }}
    >

      <div className='row mx-1 px-1  d-flex justify-content-center  flex-sm-row flex-column container-fluid  mobile-margin'>
        <div className=' col-md-7 slider-align '>
          {/* <SliderMainParticleGrey /> */}
          <img className="w-100" src={img} alt="content" layout="fullWidth" placeholder="blurred" />

        </div>

        <div className=' col-md-4 mr-5 '>
          <Form />
        </div>

      </div>
    </section >



    <section id='faq' className='pb60 pt-5'>
      <div className='container pt-5'>
        <div className='row'>
          <div className='col-lg-12 title'>
            <h2> </h2>
          </div>
          <div className='col-lg-12 mt-5'>
            <FAQ />        </div>
        </div>
      </div>
    </section>



    <section id="technologies" className='pb60 pt-5'>
      <div className='container pt-5'>
        <div className='row'>
          <div className='col-lg-12 title'>
            <h2>Technologies</h2>
          </div>
          <div className='col-lg-12'>
            {/* <Wallet /> */}
            <Technologies />
          </div>
        </div>
      </div>
    </section>






    <section className='container'>
      <div className='row'>
        <div className='col-lg-12'>
          <div className='text-center'>
            <h2>Awards and Recognitions</h2>
            <div className="small-border"></div>
          </div>
        </div>
      </div>
      <Catgor1 />
    </section>



    <section className='container'>
      <div className='row'>
        <div className='col-lg-12'>
          <div className='text-center'>
            <h2>Trusted by </h2>
            <div className="small-border"></div>
          </div>
        </div>
      </div>
      <Catgor2 />
    </section>
    {/* <section className='pb60 yelbg-2'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <h2>Hot Collections</h2>
          </div>
          <div className='col-lg-12'>
            <CarouselCollectionRedux />
          </div>
        </div>
      </div>
    </section> */}

    {/* <section className='pb60 greybg'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <h2>New Items</h2>
          </div>
          <div className='col-lg-12'>
            <CarouselNewRedux />
          </div>
        </div>
      </div>
    </section> */}

    <Footer />

  </div >
);
export default home;