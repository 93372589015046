import React, { Component, useState, useEffect, useCallback } from "react";
import Clock from "../components/Clock";
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';

import * as Yup from 'yup';

import { Formik, Field, Form, ErrorMessage, yupToFormErrors } from 'formik';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import axios from "axios";

const GlobalStyles = createGlobalStyle`
  // workaround
/* .iti {
  display:block;
  width: 100%;
}

  .intl-tel-input{
  width: 100%;
  display: block;
}

  .iti input[type=tel] {
    position: relative;
    width: 100%;
    z-index: 0;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-right: 36px;
    margin-right: 0; } */

.form-control {
  height:40px;
}
.text-danger:first-letter {
  text-transform: capitalize
}
.text-danger{
    color:red;
    padding-left:20px;
    margin-top:-15px;
    margin-bottom:20px;
}
.head-h1{
  text-align:center;
  color: #8364E2;
}
.head-h2{
  text-align:center;
}
.btn-5{
  text-align:center;
  align-items:center;
  


  /* display: block; */
    width: max-content;
    color: #fff !important;
    background: #8364E2;
    border-radius: 6px;
    letter-spacing: normal;
    font-weight: 800;
    text-decoration: none;
    padding: 8px 40px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    box-shadow: 2px 2px 20px 0px rgba(131, 100, 226, 0);
    transition: all 0.3s ease;


}

textarea.form-control {
    /* padding-top: 0.625rem !important; */
    margin-bottom: 24px;
    height:auto;
}
.thankyou {
  margin-top:50%;
  margin-bottom:50%

}





`;




const Textarea = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <div>
    <Field as="textarea" style={{ resize: "none" }} type="text" {...field} {...props} />
    {touched[field.name] &&
      errors[field.name] && <div className="error">{errors[field.name]}</div>}
  </div>
);



const ValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    // .matches(/^[A-Za-z]+([\ A-Za-z]+)*/, "The first name must contain alpha characters only")
    .matches(/^[a-z ,.'-]+$/i, "The First Name should not contain any special characters or numbers")

    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("First Name is required"),
  // .test("firstName", "The first name must contain alpha characters only",
  //     (value) => {
  //         return value.match(/^[A-Za-z]+([\ A-Za-z]+)*/);
  //     })


  lastName: Yup.string()
    .matches(/^[a-z ,.'-]+$/i, "The Last Name should not contain any special characters or numbers")
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Last Name is required"),



  email: Yup.string().email().required("Email is required"),

  // company: Yup.string()
  //   .min(2, "Too Short!")
  //   .max(50, "Too Long!")
  //   .matches(/^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/, "Company name must be a valid name"),


});







export default function Createpage() {


  const [submited, setSubmited] = useState(false)
  const [phone, setPhone] = useState("")

  const number = useCallback((event) => {
    setPhone(event)
  })


  // constructor() {
  //   super();
  //   this.onChange = this.onChange.bind(this);
  //   this.state = {
  //     phone: "",
  //     submited: false
  //   };
  // }

  // onChange(e) {
  //   var files = e.target.files;
  //   console.log(files);
  //   var filesArr = Array.prototype.slice.call(files);
  //   console.log(filesArr);
  //   document.getElementById("file_name").style.display = "none";
  //   this.setState({ files: [...this.state.files, ...filesArr] });
  // }




  if (submited) {
    return (
      <>
        <div className="shadow p-4 bg-light h-90">
          <GlobalStyles />
          <div className=" thankyou align-items-center text-center align-middle ">
            <h1 className="head-h1">
              Thank You
            </h1>
            <h2 className="head-h2">
              Thank you for contacting us we will get back to you soon
              <br />
              - Axcer Team            </h2>
            <button className=" btn-readical-red btn-5" to="/book" onClick={() => setSubmited(false)} >Go back</button>
          </div>
        </div>
      </>
    )
  } else {


    return (




      <div className="shadow p-4 bg-light h-90">


        <>
          <GlobalStyles />


          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              phone: '',
              // company: '',
              message: ''

            }}
            onSubmit={async (values) => {
              values.phone = phone
              // console.log(values)
              await new Promise((r) => setTimeout(r, 500));
              axios.post("https://k28nlyukbb.execute-api.us-east-1.amazonaws.com/dev/contact/blockchain", {
                first_name: values.firstName,
                last_name: values.lastName,
                email: values.email,
                mobile: values.phone,
                role: values.message ? values.message : " ",
                application_type: "text",
                // company: values.company,
              })
                .then(response => setSubmited(true))
                // .then(response => console.log(response))

                .catch(error => console.log(error))
              // alert(JSON.stringify(values, null, 2));
            }}
            validationSchema={ValidationSchema}

          >
            {({ touched, errors, isSubmitting, values }) => (
              <Form
                id="form-create-item"
                className="form-border  " action="#">

                <h2>Book A Consultation</h2>
                <div className="field-set">


                  <div className="spacer-single"></div>

                  <Field className="form-control " placeholder="First Name" id="firstName" name="firstName" />
                  <div>{errors.firstName && touched.firstName ? (
                    <div className='text-danger'>{errors.firstName}</div>
                  ) : null}
                  </div>
                  <div className="spacer-10"></div>
                  <Field className="form-control " type="text" placeholder="Last Name" id="lastName" name="lastName" />
                  <div>           {errors.lastName && touched.lastName ? (
                    <div className='text-danger' >{errors.lastName}</div>
                  ) : null}</div>

                  <div className="spacer-10"></div>
                  <Field className="form-control" type="email" placeholder="Your Email" id="email" name="email" />

                  <div>      {errors.email && touched.email ? <div className='text-danger '>{errors.email}</div> : null}
                  </div>

                  <div className="spacer-10"></div>
                  {/* <input type="text" name="item_title" id="item_title" className="form-control" placeholder="Phone Number" /> */}

                  <PhoneInput
                    // value={book.number}
                    inputProps={{
                      name: 'phone',
                      required: true,
                      // autoFocus: true
                    }}
                    onChange={number}
                    country={'sg'}
                    preferredCountries={['sg', 'us', 'gb']}
                    inputClass="form-control"
                    buttonClass='phone-input-button'
                    buttonStyle={{ backgroundColor: "transparent", paddingLeft: "0px", }}
                    inputStyle={{ width: "100%", height: "40px" }}
                  // value={this.state.phone}
                  // onChange={phone => this.setState({ phone })}
                  />


                  {/* <div className="input-group">
              <input type="tel" id="tel"
              // class="form-control" 
              />
              <span className="input-group-addon">Tel</span>
            </div> */}
                  {/* <input id="phone" style={{ width: "100%" }} type="tel" name="phone" className="form-control" /> */}

                  <div className="spacer-10"></div>

                  <div className="spacer-10"></div>
                  <div className="spacer-10"></div>

                  {/* <Field className="form-control" type="text" placeholder="Company Name" id="company" name="company" />

                  <div>  {errors.company && touched.company ? (
                    <div className='text-danger' >{errors.company}</div>
                  ) : null}</div>

                  <div className="spacer-10"></div> */}



                  <div className="form-floating">
                    <Field className="form-control" component={Textarea} placeholder="Message" id="message" name="message" />
                    {/* <label htmlFor="floatinginput4">Company Name</label> */}

                  </div>
                  We are collecting your information only for  inquiry related tasks.
                  <div className="spacer-10"></div>
                  <div className="spacer-10"></div>

                  <input type="submit" id="submit" className="btn-main" value="Book now" />
                </div>
              </Form>
            )}
          </Formik>

        </>

      </div>


    );



  }




}
// export default Createpage