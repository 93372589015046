import React, { useState } from "react";
import ReactDOM from "react-dom";
// import FAQ from "./FAQ";
import Faq from "react-faq-component";

import { createGlobalStyle } from 'styled-components';
const GlobalStyles = createGlobalStyle` 
.faq-row-wrapper {
    .faq-title {
        text-align: center;
        align-content: center;
        }
    .faq-body {
        padding-top:20px;
        .faq-row {

            .row-title {
                font-weight:bold;
            }

            .row-content {
                .row-content-text {
                    padding-left:10px;
                }
            }
        }
    }

}
`

function App() {
    const [rows, setRowsOption] = useState(null);
    const data = {
        title: "",
        rows: [
            {
                title: "What is the future of this world?",
                content: `<p> Web3 is the new era of the internet and also our future. During recent years web3 has been developed in various fields and became popular as a flash…
                Decentralised Network AKA Blockchain technology is the foundation of Web3. Blockchain technology is now used in every field. There are a lot of new opportunities that have emerged in recent years due to the popularity and huge advantages of Blockchain technology. </p>`
            },
            {
                title: "Why blockchain matters to your business?",
                content:
                    "  1. Lots of business opportunities are arised every second. <br /> 2. Digital freedom     <br />            3. Improved security and privacy <br />    4. Reduce costs  <br />  5. Increase efficiency  <br />    6. Scalability  <br />   7. Other well known benefits of BC such as,  <ul>   <li> Immutability </li>   <li>  Trust </li> <li>   Transparency </li> <li>  Traceability </li>  </ul>"
            },
            {
                title: "Why do we want collaborate together",
                content: `As a team of experienced software engineers we can give you a great insight about new technologies and also how to adapt them into your business to become a game changer in the industry.
                While we are using agile methodology It helps us to develop a superior product in a relatively short period of time and reduce the cost. `
            },
            // {
            //     title: "What is the package version",
            //     content: <p>current version is 1.2.1</p>
            // }
        ],
        styles: {
            rowTitleTextSize: '19px',
            rowContentTextSize: '16px',
            rowTitleColor: "black",
            rowContentColor: "111111",


        }
    };
    // useEffect(() => {
    //     if (rows) {
    //         setTimeout(() => {
    //             rows[0].expand();
    //         }, 2500);

    //         setTimeout(() => {
    //             rows[0].close();
    //         }, 5000);

    //         setTimeout(() => {
    //             rows[0].scrollIntoView();
    //             // rows[0].scrollIntoView(true);
    //         }, 10000);
    //     }
    // }, [rows]);

    return (
        <div>
            <GlobalStyles />
            <h2 className="section-title text-center">Why Blockchain?</h2>

            <div className="faq-style-wrapper">
                <Faq data={data} getRowOptions={setRowsOption} />
            </div>
        </div>
    );
}

// const rootElement = document.getElementById("root");
// ReactDOM.render(<App />, rootElement);
export default App;