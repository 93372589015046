import React from 'react';
import { Link } from 'react-router-dom';

import web3Img from '../../assets/icon/web3.png'
import metaImg from '../../assets/icon/meta-icon.png'
import defiImg from '../../assets/icon/defi.webp'
import nftImg from '../../assets/icon/nft-icon.png'
import tokenImg from '../../assets/icon/tokenomics.png'
import digitalImg from '../../assets/icon/digital-identity.webp'



const catgor = () => (
    <div className='row justify-content-center'>
        <div className="col-md-2 col-sm-4 col-6 mb-3">
            <Link className="icon-box style-2 rounded" to="">
                {/* <i className="fa-brands fa-meta"></i> */}
                <img width="40px" src={web3Img} />
                <span>Web3.0</span>
            </Link>
        </div>
        <div className="col-md-2 col-sm-4 col-6 mb-3">
            <Link className="icon-box style-2 rounded" to="">
                {/* <i className="fa-brands fa-meta"></i> */}
                <img width="40px" src={metaImg} />
                <span>Metaverse</span>
            </Link>
        </div>
        <div className="col-md-2 col-sm-4 col-6 mb-3">
            <Link className="icon-box style-2 rounded" to="">
                {/* <i className="fa fa-music"></i> */}
                <img width="40px" src={defiImg} />

                <span>Defi</span>
            </Link>
        </div>
        <div className="col-md-2 col-sm-4 col-6 mb-3">
            <Link className="icon-box style-2 rounded" to="">
                {/* <i className="fa fa-search"></i> */}
                <img width="40px" src={nftImg} />

                <span>NFT</span>
            </Link>
        </div>
        <div className="col-md-2 col-sm-4 col-6 mb-3">
            <Link className="icon-box style-2 rounded" to="">
                {/* <i className="fa fa-globe"></i> */}
                <img width="40px" src={tokenImg} />

                <span>Tokenomics</span>
            </Link>
        </div>
        <div className="col-md-2 col-sm-4 col-6 mb-3">
            <Link className="icon-box style-2 rounded" to="">
                {/* <i className="fa fa-vcard"></i> */}
                <img width="40px" src={digitalImg} />

                <span> Digital Identity</span>
            </Link>
        </div>

    </div>
);
export default catgor;