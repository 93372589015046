import React from 'react';
import SliderMainParticleGrey from '../components/Slider';
import CarouselCollectionRedux from '../components/CarouselCollectionReduxNew';
import CarouselNewRedux from '../components/CarouselNewReduxNew';
import AuthorListRedux from '../components/AuthorListRedux';
import Catgor from '../components/Catgor';
// import Footer from '../components/footer';
import Footer from '../components/Footer/FooterFive'
import Form from './form'

import FeatureBox from '../components/FeatureBox';


import Wallet from '../components/wallet';



import { createGlobalStyle } from 'styled-components';
const GlobalStyles = createGlobalStyle`
  .navbar{
    background: #fff;
  }
  .col-white {
      color: #0d0c22;
  }
  .de_count{
    h3{
      font-size: 36px;
      margin-bottom: 0;
      span {
        font-size: 36px;
      }
    }
  }
  .btn-main.inline.white{
    background: rgba(131,100,266, .2);
  }
  .yelbg{
    background-color: rgb(255, 249, 225);
  }
  .yelbg-2{
    background-color: rgb(247, 255, 231);
  }
  .greybg{
    background-color: rgb(246, 245, 251);
  }
  h2{
    font-size: 30px;
    margin-bottom: 20px;
    margin-top:0;
  }
  .pb60 {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  @media only screen and (max-width: 1199px) { 
    .breadcumb.h-vh{
      background-image: none !important;
    }
  }
`;


const home = () => (
  <div>
    <GlobalStyles />
    <section className='m-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 text-center'>
            <h2>Technologies</h2>
          </div>
          <div className='col-lg-12'>
            <Wallet />
          </div>
        </div>
      </div>
    </section>

    <Footer />

  </div >
);
export default home;