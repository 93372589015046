export default [{ id: 'ea-b', source: 'a', target: 'b', style: { color: "#8364E2" } },
{ id: 'ea-c', source: 'a', target: 'c', style: { color: "8364E2" } },
{ id: 'eb-d', source: 'b', target: 'd', style: { color: "8364E2" } },
{ id: 'ec-d', source: 'c', target: 'd', style: { color: "8364E2" } },
{ id: 'ea-f', source: 'a', target: 'f', style: { color: "8364E2" } },
{ id: 'ea-e', source: 'a', target: 'e', style: { color: "8364E2" } },
{ id: 'ed-g', source: 'd', target: 'g', style: { color: "8364E2" } },
{ id: 'eb-g', source: 'b', target: 'g', style: { color: "8364E2" } },
{ id: 'eb-c', source: 'b', target: 'c', style: { color: "8364E2" } },
{ id: 'ef-h', source: 'f', target: 'h', style: { color: "8364E2" } },
{ id: 'ef-g', source: 'f', target: 'g', style: { color: "8364E2" } },
{ id: 'ee-h', source: 'e', target: 'h', style: { color: "8364E2" } }];
