import React from 'react';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";


import imgSolidity from '../../assets/technologies/solidity.svg'
import imgWeb3 from '../../assets/technologies/web3js.png'
import imgEthereum from '../../assets/technologies/ethereum.png'
import imgRemix from '../../assets/technologies/remix.png'
import imgSolana from '../../assets/technologies/solana.png'
import imgTypescript from '../../assets/technologies/ts.png'
import imgRust from '../../assets/technologies/rust.png'
import imgBinance from '../../assets/technologies/binance.png'

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const inline = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  .d-inline{
    display: inline-block;
   }
`;

const slidermainparticle = () => (

  // <div style={{ marginLeft: "-100%", background: `radial-gradient(circle, rgba(131,100,226,0.8802871490393032) 0%, rgba(255,255,255,0) 26%, rgba(255,255,255,0) 35%, rgba(255,255,255,0) 79%, rgba(255,255,255,0) 100%)` }}>
  //   <div style={{ marginLeft: "50%" }}>


  <div >
    <div className="spacer-single"></div>
    <h6> <span className="text-uppercase color">Axcer Innovations</span></h6>
    <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={900} triggerOnce>
      <h1 className="col-white">   <span className="color"> Skyrocket <i class="fa fa-rocket"></i>
      </span>  your business with foundation of future </h1>
    </Reveal>
    <Reveal className='onStep' keyframes={fadeInUp} delay={600} duration={900} triggerOnce>
      <p className="lead col-white">
        Foundation of future world is now breakout  all over the world,
        This is the best time to take step forward & strengthen your business to become a game changer in the business world.
      </p>
    </Reveal>
    <div className="spacer-10"></div>
    {/* <Reveal className='onStep d-inline' keyframes={inline} delay={800} duration={900} triggerOnce>
      <span onClick={() => window.open("#", "_self")} className="btn-main inline lead">Explore</span>
      <span onClick={() => window.open("#", "_self")} className="btn-main inline white lead">Create</span>
      <div className="mb-sm-30"></div>
    </Reveal> */}

    {/* <Reveal
      className='onStep'
      keyframes={fadeInUp} delay={1000} duration={600} triggerOnce
    >
      <div className="wallet-images-group-1">
        <img src={imgSolidity} alt="" />
        <img src={imgWeb3} alt="" />
        <img src={imgEthereum} alt="" />
        <img src={imgRemix} alt="" />
        <img src={imgSolana} alt="" />
        <img src={imgTypescript} alt="" />
        <img src={imgRust} alt="" />
        <img src={imgBinance} alt="" />
      </div>
    </Reveal> */}






    {/* 
    <Reveal className='onStep d-inline' keyframes={inline} delay={900} duration={1200} triggerOnce>
      <div className="row">
        <div className="spacer-single"></div>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-4 mb30">
            <div className="de_count text-left">
              <h3><span>94215</span></h3>
              <h5 className="id-color">Collectibles</h5>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-4 mb30">
            <div className="de_count text-left">
              <h3><span>27</span>k</h3>
              <h5 className="id-color">Auctions</h5>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-4 mb30">
            <div className="de_count text-left">
              <h3><span>4</span>k</h3>
              <h5 className="id-color">NFT Artist</h5>
            </div>
          </div>
        </div>
      </div>
    </Reveal> */}



  </div>
  //   </div>
  // </div>
);

export default slidermainparticle;