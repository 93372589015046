// import { Link } from '~components'
import { Link } from 'react-router-dom'
import React from 'react'
// import { Container, Row, Col } from 'react-bootstrap'
// import { Images } from '~data'

import Footer from "./style"
export default function FooterFive() {
    return (
        <Footer backgroundColor="#f3f4f6">
            <div className="container">
                <Footer.Box pb="25px">
                    <div className="row justify-content-center justify-content-lg-between">
                        <div className="col" xs="12">
                            <Footer.Logo>
                                {/* Brand Logo*/}
                                <Footer.Box mb="30px">
                                    <Link to="#">
                                        <img src="/img/logo.png" alt="logo" />
                                    </Link>
                                </Footer.Box>
                            </Footer.Logo>

                            <Footer.Text as="p">Bring your idea into finger tips.</Footer.Text>
                            {/* <Footer.Menu>
                                <Footer.MenuItems><a href="/">Home</a></Footer.MenuItems>
                              
                                <Footer.MenuItems><a href="/portfolio">Portfolio</a></Footer.MenuItems>
                                <Footer.MenuItems><a href="/career">Career</a></Footer.MenuItems>
                                <Footer.MenuItems><a href="/contact">Contact Us</a>
                                </Footer.MenuItems>
                            </Footer.Menu> */}
                        </div>
                    </div>
                </Footer.Box>
                <Footer.Copyright>
                    <Footer.SocialShare>
                        <Footer.SocialShareItem>
                            <a href="https://www.linkedin.com/company/axcer/">
                                <i className="fa fa-linkedin" />
                            </a>
                        </Footer.SocialShareItem>

                        <Footer.SocialShareItem>
                            <a href="https://www.facebook.com/AxcerInnovations">
                                <i className="fa fa-facebook" />
                            </a>
                        </Footer.SocialShareItem>
                        {/* 
                        <Footer.SocialShareItem>
                            <a href="#">
                                <i className="fab fa-twitter" />
                            </a>
                        </Footer.SocialShareItem> */}
                        <Footer.SocialShareItem>
                            <a href="https://github.com/Axcer-io">
                                <i className="fa fa-github" />
                            </a>
                        </Footer.SocialShareItem>

                        <Footer.SocialShareItem>
                            <a href="https://www.instagram.com/axcer.io/">
                                <i className="fa fa-instagram" />
                            </a>
                        </Footer.SocialShareItem>



                    </Footer.SocialShare>
                    <Footer.CopyrightText>© 2021 Axcer Innovations, All Rights Reserved</Footer.CopyrightText>
                </Footer.Copyright>
            </div>
        </Footer>
    )
}