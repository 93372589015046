import img from "../../assets/clients/dpassive.png"
export default [
    {
        id: 'a',
        type: 'input',
        data: { label: '' },
        position: { x: 0, y: 55 },
        draggable: false,
        // background: img
        style: { backgroundImage: 'url("./flow/4.png")', backgroundSize: "100%", height: "100px", width: "100px", borderRadius: "40px", borderColor: "#8364E2" },
        sourcePosition: "right"
    },

    {
        id: 'b',
        data: { label: '' },
        labelBgStyle: img,
        position: { x: 200, y: 125 },
        draggable: false,
        style: { backgroundImage: 'url("./flow/2.png")', backgroundSize: "100%", height: "70px", width: "70px", borderRadius: "40px", borderColor: "#8364E2" },
        targetPosition: "left",
        sourcePosition: "right"

    },
    {
        id: 'c',
        data: { label: '' },
        data: { background: img },
        position: { x: 450, y: 80 },
        draggable: false,
        style: { backgroundImage: 'url("./flow/3.png")', backgroundSize: "100%", height: "70px", width: "70px", borderRadius: "40px", borderColor: "#8364E2" },
        targetPosition: "left"

    },
    {
        id: 'd',
        data: { label: '' },
        data: { background: img },
        position: { x: 450, y: 200 },
        draggable: false,
        style: { backgroundImage: 'url("./flow/4.png")', backgroundSize: "100%", height: "100px", width: "100px", borderRadius: "40px", borderColor: "#8364E2" },
        sourcePosition: "left"

    },
    {
        id: 'e',
        data: { label: '' },
        data: { background: img },
        position: { x: 0, y: 200 },
        draggable: false,
        style: { backgroundImage: 'url("./flow/1.png")', backgroundSize: "100%", height: "70px", width: "70px", borderRadius: "40px", borderColor: "#8364E2" },
        targetPosition: "right"

    },
    {
        id: 'f',
        data: { label: '' },
        data: { background: img },
        position: { x: 130, y: 200 },
        draggable: false,
        style: { backgroundImage: 'url("./flow/2.png")', backgroundSize: "100%", height: "70px", width: "70px", borderRadius: "40px", borderColor: "#8364E2" }

    },
    {
        id: 'g',
        type: 'output',
        data: { label: "" },
        position: { x: 300, y: 300 },
        draggable: false,
        style: { backgroundImage: 'url("./flow/3.png")', backgroundSize: "100%", height: "80px", width: "80px", borderRadius: "40px", borderColor: "#8364E2" }


    },
    {
        id: 'h',
        type: 'output',
        data: { label: "" },
        position: { x: 50, y: 300 },
        draggable: false,
        style: { backgroundImage: 'url("./flow/1.png")', backgroundSize: "100%", height: "80px", width: "80px", borderRadius: "40px", borderColor: "#8364E2" },



    },
];
