import React from 'react';
import { Link } from 'react-router-dom';

import globalimg from '../../assets/clients/global.png'
import pandemiumimg from '../../assets/clients/pandemium.jpeg'

import lonoimg from '../../assets/clients/lono.jpeg'
import dpassiveimg from '../../assets/clients/dpassive.png'



import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
.award-box {
    height: 150px;
    display: block;
  text-align: center;
  border: solid 1px #dddddd;
  padding: 20px 0 20px 0;
  text-decoration: none;
 background-color:#fff;
  

}


.award-box.style-2 {
  border: none;
  background: #ffffff;
}



.award-img {
    max-width:200px
}
@media (max-width: 544px) {  
    .award-img {
    max-width:150px
}
}
.award-img2 {
    max-width:270px
}
@media (max-width: 544px) {  
    .award-img2 {
    max-width:200px
}
}
`




const catgor = () => (
    <>
        <GlobalStyles />
        <div className='row d-flex justify-content-center'>
            <div className="col-md-4 col-sm-8 col-10 mb-3">
                <div className=" style-2 award-box rounded d-flex align-items-center justify-content-center" to="">
                    {/* <i className="fa-brands fa-meta"></i> */}
                    <img className='award-img' src={globalimg} />
                    {/* <span>Web3.0</span> */}
                </div>
            </div>

            <div className="col-md-4 col-sm-8 col-10 mb-3">
                <div className=" style-2 award-box rounded d-flex align-items-center justify-content-center" to="">
                    <img className='award-img2' src={pandemiumimg} />
                </div>
            </div>
            <div className="col-md-4 col-sm-8 col-10 mb-3">
                <div className=" style-2 award-box rounded d-flex align-items-center justify-content-center" to="">
                    <img className='award-img' src={lonoimg} />
                </div>
            </div>
            <div className="col-md-4 col-sm-8 col-10 mb-3">
                <div className=" style-2 award-box rounded d-flex align-items-center justify-content-center" to="">
                    <img className='award-img' src={dpassiveimg} />
                </div>
            </div>

        </div>
    </>
);
export default catgor;